<template>
	<v-skeleton-loader
		:class="className"
		:type="type"
	/>
</template>

<script>
export default {
	props: {
		className: String,
		type: String
	}
}
</script>
