import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT, DATE_FORMAT_MONTH_YEAR } from '../date-formatting.js'
import { getRandomColorByString } from '../getRandomColorByString.js'
/// interval = 0 (day), 1 (week), 2 (month)

export const HEALTH_CHART_TYPE = 9

export const getDashboardData = ({
	interval,
	startDate,
	endDate,
	formId,
	brandId,
	dashboardId,
	domain
}) =>
	configApi
		.post('api/Dashboard/GetDashboardInfo', {
			startDate,
			endDate,
			widgetId: formId,
			interval,
			brandId,
			dashboardId,
			domain
		})
		.then(response => response.data)

const ChartTypes = {
	1: 'AreaChart',
	2: 'BarChart',
	3: 'BubbleChart',
	4: 'PieChart',
	5: 'LineChart',
	6: 'PolarChart',
	7: 'RadarChart',
	8: 'ScatterChart',
	9: 'HealthChart'
}
const INTERVALS = {
	DAY: 0,
	MONTH: 2
}

export const getDashboardChartsData = ({
	interval,
	startDate,
	endDate,
	formId,
	brandId,
	dashboardId
}) =>
	configApi
		.post('api/Dashboard/GetDashboardCharts', {
			startDate,
			endDate,
			widgetId: formId,
			interval,
			brandId,
			dashboardId
		})
		.then(response => {
			return response.data.charts.map(chart => ({
				...chart,
				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						yPrimary: {
							min: chart.min,
							max: chart.max
						}
					}
				},
				chartData: {
					datasets: chart.chartData.datasets
						? chart.chartData.datasets.map(dataset => ({
							data: dataset.data,
							label: dataset.label,
							backgroundColor: getRandomColorByString(`${dataset.label}`)
						}))
						: [],
					labels: chart.chartData.labels.map(label => {
						return interval !== INTERVALS.MONTH ? format(new Date(label), DATE_FORMAT) : format(new Date(label), DATE_FORMAT_MONTH_YEAR)
					})
				},
				chartType: ChartTypes[chart.chartTypeId]
			}))
		})

export const getDomains = () => {
	return configApi.get(`api/Dashboard/GetDomains`).then(res => res.data)
}

export const getCookieWidgets = () => {
	return configApi.get(`api/Dashboard/GetCookieWidgets`).then(res => res.data)
}
