<template>
	<BarChartGenerator
		:chart-options="chartOptions"
		:chart-data="chartData"
		:chart-id="chartId"
		:dataset-id-key="datasetIdKey"
		:plugins="plugins"
		:css-classes="cssClasses"
		:styles="styles"
		:width="width"
		:height="height"
	/>
</template>

<script>
import { Bar as BarChartGenerator } from 'vue-chartjs/legacy'

import {
	Chart as ChartJS,
	Title,
	Tooltip,
	LinearScale,
	CategoryScale,
	BarElement,
	Legend
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
	name: 'bar-chart',
	components: {
		BarChartGenerator
	},
	props: {
		chartId: {
			type: String,
			default: 'bar-chart'
		},
		datasetIdKey: {
			type: String,
			default: 'label'
		},
		width: {
			type: Number,
			default: 400
		},
		height: {
			type: Number,
			default: 400
		},
		cssClasses: {
			default: '',
			type: String
		},
		styles: {
			type: Object,
			default: () => {}
		},
		plugins: {
			type: Array,
			default: () => []
		},
		chartData: {
			type: Object
		},
		chartOptions: {
			type: Object
		}
	}
}
</script>
