<template>
	<SectionCard>
		<template #title>
			{{ data.title }}
		</template>
		<template #body>
			<div v-if="chartData.labels.length > 0">
				<v-btn-toggle
					v-model="sortValue"
					tile
					color="deep-purple accent-3"
					group
				>
					<v-btn
						v-for="action in sortActions"
						:key="action.value"
						:value="action.value"
					>
						{{ action.text }}
					</v-btn>
				</v-btn-toggle>
				<BarChart
					:chart-data="sortedData"
					:chart-options="chartOptions"
				/>
			</div>
			<div v-else>
				No data available for selected filters.
			</div>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import BarChart from '../../../../../../shared/components/chart/bar-chart.vue'
import { getRandomColorByString } from '../../../../../../shared/utils/getRandomColorByString.js'

const SORT_BUTTON_ACTIONS = {
	Asc: 'Asc',
	Opt_in: 'Opt-in',
	Opt_out: 'Opt-out'
}

export default {
	components: {
		BarChart,
		SectionCard
	},
	props: {
		items: Array,
		selected: Function,
		data: Object
	},
	data () {
		return {
			sortValue: SORT_BUTTON_ACTIONS.Asc,
			sortActions: [
				{
					text: 'A-Z',
					value: SORT_BUTTON_ACTIONS.Asc
				},
				{
					text: SORT_BUTTON_ACTIONS.Opt_in,
					value: SORT_BUTTON_ACTIONS.Opt_in
				},
				{
					text: SORT_BUTTON_ACTIONS.Opt_out,
					value: SORT_BUTTON_ACTIONS.Opt_out
				}
			],
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				indexAxis: 'y'
			}
		}
	},
	computed: {
		sortedData () {
			const { labels, datasets } = this.chartData

			if (!this.sortValue) return this.chartData

			const arrayOfObj = labels.map((label, i) => {
				return {
					label,
					data: datasets.map(dataset => {
						const data = dataset.data[i]

						return {
							...dataset,
							data
						}
					})
				}
			})

			const sortedArrayOfObj = arrayOfObj.sort((a, b) => {
				if (this.sortValue === SORT_BUTTON_ACTIONS.Asc) {
					return a.label < b.label ? 1 : -1
				}

				if (this.sortValue === SORT_BUTTON_ACTIONS.Opt_in) {
					const itemA = a.data.find(elem => elem.label === SORT_BUTTON_ACTIONS.Opt_in)
					const itemB = b.data.find(elem => elem.label === SORT_BUTTON_ACTIONS.Opt_in)

					return itemA.data < itemB.data ? 1 : -1
				}

				if (this.sortValue === SORT_BUTTON_ACTIONS.Opt_out) {
					const itemA = a.data.find(elem => elem.label === SORT_BUTTON_ACTIONS.Opt_out)
					const itemB = b.data.find(elem => elem.label === SORT_BUTTON_ACTIONS.Opt_out)

					return itemA.data < itemB.data ? 1 : -1
				}

				return -1
			})

			const nextLabels = []
			const itemsMap = {}

			sortedArrayOfObj.forEach(d => {
				nextLabels.push(d.label)

				d.data.forEach(item => {
					if (itemsMap.hasOwnProperty(item.label)) {
						itemsMap[item.label].data.push(item.data)
					} else {
						itemsMap[item.label] = {
							...item,
							data: [item.data]
						}
					}
				})
			})

			return {
				labels: nextLabels,
				datasets: Object.values(itemsMap)
			}
		},
		chartData () {
			const { xAxis, series } = this.data

			const labels = xAxis.categories
			const datasets = []

			series.forEach(({ data, name }, i) => {
				datasets.push({
					label: name,
					data,
					backgroundColor: getRandomColorByString(name)
				})
			})

			return {
				labels,
				datasets
			}
		}
	}
}
</script>
