<template>
	<CookieModuleLayout>
		<template #header-after>
			<CookieModuleFilter
				:period-value="filter.periodValue"
				:widget-value="filter.widgetValue"
				:brand-value="filter.brandId"
				:dashboard-value="filter.dashboardValue"
				:start-date="filter.startDate"
				:end-date="filter.endDate"
				:domain-value="filter.domainValue"
				@dashboard-change="filterDashboardValueChange"
				@cookie-widget-change="filterWidgetValueChange"
				@period-change="filterPeriodValueChange"
				@brand-change="filterBrandChange"
				@start-date-change="filterDateChange('startDate', $event)"
				@end-date-change="filterDateChange('endDate', $event)"
				@domain-change="filterDomainChange"
			/>
		</template>
		<template #content>
			<v-row
				v-if="healthChecks && filter.dashboardValue !== legacyMainDashboards"
				dense
			>
				<v-col
					v-for="healthCheck in healthChecks"
					:key="healthCheck.chartId"
					cols="4"
				>
					<HealthCheckChart
						:title="healthCheck.title"
						:amber-status="healthCheck.amberStatus"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="filter.dashboardValue !== legacyMainDashboards"
				dense
			>
				<v-col
					v-for="chart in charts"
					:key="chart.chartId"
					cols="6"
				>
					<CookieModuleDynamicChart
						v-if="chart.chartTypeId !== HEALTH_CHART_TYPE"
						:chart="chart"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col
					cols="8"
					class="cassie-vertical-md"
				>
					<template v-if="!filter.dashboardValue">
						<CookieModuleTransactionChart
							v-if="!loadingDashboardData"
							:data="dashboardData.transactionsByPeriod"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>

						<CookieModuleSubmissionChart
							v-if="!loadingDashboardData"
							:data="dashboardData.optInOptOutSubmissionsPercentage"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>

						<CookieModuleChannelChart
							v-if="!loadingDashboardData"
							:data="dashboardData.OptInOptOutPercentagebyChannel"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>

						<CookieModuleTrendChart
							v-if="!loadingDashboardData"
							:data="dashboardData.interactionTypeTrendPercentage"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>
					</template>
				</v-col>
				<v-col
					cols="4"
					class="cassie-vertical-md"
				>
					<template v-if="!filter.dashboardValue">
						<CookieModuleBreakdownChart
							v-if="!loadingDashboardData"
							:data="dashboardData.interactionTypeBreakdown"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>

						<CookieModuleManualChart
							v-if="!loadingDashboardData"
							:data="dashboardData.manualOptInOptOutPercentage"
						/>
						<SkeletonLoader
							v-else
							type="card"
						/>
					</template>
				</v-col>
			</v-row>
		</template>
	</CookieModuleLayout>
</template>

<script>

import { sub, formatISO, differenceInDays } from 'date-fns'
import CookieModuleLayout from './cookie-module-layout.vue'
import CookieModuleTransactionChart from './cookie-module-charts/cookie-module-transaction-chart.vue'
import CookieModuleSubmissionChart from './cookie-module-charts/cookie-module-submission-chart.vue'
import CookieModuleChannelChart from './cookie-module-charts/cookie-module-channel-chart.vue'
import CookieModuleBreakdownChart from './cookie-module-charts/cookie-module-breakdown-chart.vue'
import CookieModuleFilter from './cookie-module-filter.vue'
import CookieModuleTrendChart from './cookie-module-charts/cookie-module-trend-chart.vue'
import CookieModuleManualChart from './cookie-module-charts/cookie-module-manual-chart.vue'
import CookieModuleDynamicChart from './cookie-module-charts/cookie-module-dynamic-chart.vue'
import SkeletonLoader from '../../../../../shared/components/skeleton-loader.vue'
import HealthCheckChart from '../../../../../shared/components/chart/health-check-chart.vue'
import { getDashboardData as getDashboardDataRequest, getDashboardChartsData as getDashboardChartsWithData, HEALTH_CHART_TYPE } from '../../../../../shared/utils/api/cookie-module.js'
import { Periods } from './cookie-module-constants.js'
import { waitForLoad, brandOptions } from '../../../../../shared/state/brands.js'
const legacyMainDashboards = 0

export default {
	components: {
		SkeletonLoader,
		CookieModuleManualChart,
		CookieModuleTrendChart,
		CookieModuleFilter,
		CookieModuleBreakdownChart,
		CookieModuleChannelChart,
		CookieModuleSubmissionChart,
		CookieModuleLayout,
		CookieModuleTransactionChart,
		CookieModuleDynamicChart,
		HealthCheckChart
	},
	setup () {
		return {
			getDashboardChartsWithData,
			getDashboardDataRequest,
			legacyMainDashboards,
			HEALTH_CHART_TYPE,
			brandOptions
		}
	},
	data () {
		return {
			loadingDashboardData: true,
			dashboardData: null,
			charts: [],
			filter: {
				widgetValue: -1,
				periodValue: Periods.LastWeek,
				brandId: null,
				dashboardValue: 0,
				startDate: this.getIsoDate(sub(new Date(), { weeks: 1 })),
				endDate: this.getIsoDate(new Date()),
				interval: 0,
				domainValue: 'All'
			}
		}
	},
	computed: {
		healthChecks () {
			return this.charts.filter(({ chartTypeId }) => chartTypeId === HEALTH_CHART_TYPE)
		},
		defaultFilters () {
			return {
				widgetValue: -1,
				periodValue: Periods.LastWeek,
				brandId: null,
				dashboardValue: 0,
				startDate: this.getIsoDate(sub(new Date(), { weeks: 1 })),
				endDate: this.getIsoDate(new Date())
			}
		}
	},
	async mounted () {
		await waitForLoad()
		this.defaultBrandInSelect()
		this.getDashboardData()
	},
	methods: {
		defaultBrandInSelect () {
			if (brandOptions.value.length === 1) this.filter.brandId = brandOptions.value[0].value
		},
		getDashboardData (customDates = true) {
			const params = this.getDashboardDataParams(this.filter, customDates)
			this.makeDashboardDataRemoteRequest(params)
		},
		getIsoDate (date) {
			return formatISO(date, { representation: 'date' })
		},
		getDashboardDataParams (dashboardFilter, customDates = true) {
			const interval = {
				day: 0,
				week: 1,
				month: 2
			}
			const getInterval = filter => {
				switch (filter.periodValue) {
					case Periods.LastWeek:
					case Periods.LastMonth:
					case Periods.Custom:
						return interval.day
					case Periods.Last3Months:
					case Periods.Last6Months:
						return interval.week
					case Periods.Last12Months:
					default:
						return interval.month
				}
			}

			if (customDates) {
				const daysDifference = differenceInDays(new Date(this.filter.endDate), new Date(this.filter.startDate))

				if (daysDifference <= 31) {
					this.filter.interval = 0
				} else if (daysDifference >= 32 && daysDifference <= 183) {
					this.filter.interval = 1
				} else {
					this.filter.interval = 2
				}
			} else {
				let duration

				switch (dashboardFilter.periodValue) {
					case Periods.Custom:
						duration = { weeks: 1 }
						break
					case Periods.LastWeek:
						duration = { weeks: 1 }
						break
					case Periods.LastMonth:
						duration = { months: 1 }
						break
					case Periods.Last3Months:
						duration = { months: 3 }
						break
					case Periods.Last6Months:
						duration = { months: 6 }
						break
					case Periods.Last12Months:
					default:
						duration = { years: 1 }
				}
				this.filter.endDate = this.getIsoDate(new Date())
				this.filter.startDate = this.getIsoDate(sub(new Date(), duration))
			}
			return {
				startDate: dashboardFilter.startDate,
				endDate: dashboardFilter.endDate,
				interval: customDates ? dashboardFilter.interval : getInterval(dashboardFilter),
				formId: dashboardFilter.widgetValue,
				brandId: dashboardFilter.brandId === null && this.brandOptions.length === 0 ? 0 : dashboardFilter.brandId,
				dashboardId: dashboardFilter.dashboardValue,
				domain: dashboardFilter.domainValue
			}
		},
		async makeDashboardDataRemoteRequest (params) {
			this.loadingDashboardData = true
			try {
				if (params.dashboardId === legacyMainDashboards) {
					this.dashboardData = await this.getDashboardDataRequest(params)
				} else {
					this.charts = await this.getDashboardChartsWithData(params)
				}
			} catch (err) {
				console.error(err)
			} finally {
				this.loadingDashboardData = false
			}
		},
		filterDashboardValueChange (event) {
			this.filter.dashboardValue = event
			this.getDashboardData()
		},
		filterWidgetValueChange (event) {
			this.filter.widgetValue = event
			this.getDashboardData()
		},
		filterBrandChange (event) {
			this.filter.brandId = event
			this.getDashboardData()
		},
		filterPeriodValueChange (event) {
			this.filter.periodValue = event
			this.getDashboardData(false)
		},
		filterDateChange (type, event) {
			this.filter[type] = event
			this.getDashboardData()
		},
		filterDomainChange (event) {
			this.filter.domainValue = event
			this.getDashboardData()
		}
	}
}
</script>
