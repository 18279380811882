<template>
	<SectionCard>
		<template #title>
			{{ data.title }}
		</template>
		<template #body>
			<DoughnutChart
				v-if="containsData"
				:height="300"
				:chart-data="chartData"
				:chart-options="chartOptions"
			/>
			<div v-else>
				No data available for selected filters.
			</div>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import DoughnutChart from '../../../../../../shared/components/chart/doughnut-chart.vue'
import { getRandomColorByString } from '../../../../../../shared/utils/getRandomColorByString.js'

export default {
	components: {
		DoughnutChart,
		SectionCard
	},
	props: {
		items: Array,
		selected: Function,
		data: Object
	},
	data () {
		return {
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	computed: {
		chartData () {
			const { series } = this.data

			const labels = []
			const datasets = []

			series.forEach(({ data }) => {
				const d = []

				data.forEach(({ name, y }) => {
					labels.push(name)
					d.push(y)
				})

				datasets.push({
					label: '',
					data: d,
					backgroundColor: labels.map(label => getRandomColorByString(label))
				})
			})

			return {
				labels,
				datasets
			}
		},
		containsData () {
			const totalCount = this.chartData.datasets.reduce((acc, { data }) => {
				return acc + data.reduce((acc, curr) => acc + curr, 0)
			}, 0)
			return totalCount > 0
		}
	}
}
</script>
