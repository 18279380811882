<template>
	<FilterBar
		hide-brand-filter
		hide-search-query-filter
		:show-clear-filters="false"
		open-by-default
	>
		<template #after-filters>
			<v-row
				dense
				class="cassie-input-width-xl"
			>
				<v-col cols="3">
					<Dropdown
						:value="dashboardValue"
						:items="dashboardOptions"
						:label="'Dashboard' | useLabels"
						class="cassie-input-width-xl"
						@change="event => changeDashboardValue(event)"
					/>
				</v-col>
				<v-col
					v-if="showCookieFilter"
					cols="3"
				>
					<Dropdown
						:value="widgetValue"
						:items="widgetOptions"
						custom-sort
						label="Cookie Widget"
						class="cassie-input-width-xl"
						@change="event => $emit('cookie-widget-change', event)"
					/>
				</v-col>
				<v-col cols="3">
					<Dropdown
						:value="periodValue"
						:items="periodOptions"
						custom-sort
						label="Period"
						class="cassie-input-width-xl"
						@change="event => $emit('period-change', event)"
					/>
				</v-col>
				<v-col
					v-if="showBrandFilter"
					cols="3"
				>
					<Dropdown
						:value="brandValue"
						:items="brandFilterItems"
						custom-sort
						:label="'Brand' | useLabels"
						class="cassie-input-width-xl"
						@change="event => $emit('brand-change', event)"
					/>
				</v-col>
				<v-spacer
					v-if="!showCookieFilter"
				/>
			</v-row>
			<v-row
				dense
				class="cassie-input-width-xl mr-2"
			>
				<v-col
					v-if="showCookieFilter"
					cols="3"
				>
					<Dropdown
						:value="domainValue"
						:items="domainItems"
						label="Domain"
						class="cassie-input-width-xl"
						@change="event => $emit('domain-change', event)"
					/>
				</v-col>
				<v-col
					v-if="periodValue === Periods.Custom"
					cols="3"
				>
					<DatePicker
						:date="startDate"
						:max="endDate"
						label="Start Date"
						class="cassie-input-width-xl"
						@update:date="event => $emit('start-date-change', event)"
					/>
				</v-col>
				<v-col
					v-if="periodValue === Periods.Custom"
					cols="3"
				>
					<DatePicker
						:date="endDate"
						:min="startDate"
						label="End Date"
						class="cassie-input-width-xl"
						@update:date="event => $emit('end-date-change', event)"
					/>
				</v-col>
				<v-col
					:cols="showCookieFilter ? 3 : 6"
				/>
			</v-row>
		</template>
	</FilterBar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import DatePicker from '../../../../../shared/components/date-picker.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { Periods } from './cookie-module-constants.js'
import { CAN_SEE_CONSENT_BANNERS, CAN_SEE_COOKIE_BANNERS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { waitForLoad, brandOptions } from '../../../../../shared/state/brands.js'
import { getDomains, getCookieWidgets } from '../../../../../shared/utils/api/cookie-module.js'

export default {
	components: {
		Dropdown,
		DatePicker,
		FilterBar
	},
	props: {
		widgetValue: Number,
		periodValue: Number,
		brandValue: Number,
		startDate: String,
		endDate: String,
		domainValue: String
	},
	setup () {
		return {
			brandOptions,
			Periods
		}
	},
	data () {
		return {
			consentBanners: [{
				id: -1,
				name: 'All Widgets',
				brand: null
			}],
			dashboardValue: null,
			domains: []
		}
	},
	computed: {
		...mapGetters('brands', ['brands']),
		...mapGetters('dashboards', ['dashboards']),
		...mapGetters('auth', ['productAreaPermission', 'userDefaultDashboardId']),
		widgetOptions () {
			const banners = this.consentBanners.map(({ id, name, brandID }) => ({
				value: id,
				text: name,
				brand: brandID
			})).filter(x => x.brand === this.brandValue || this.brandValue == null || x.brand == null)

			const allWidgetsItem = banners.find(x => x.value === -1)
			const alphabeticalSort = banners.filter(x => x.value !== -1).sort((a, b) => a.text.localeCompare(b.text))
			const sortedBanners = allWidgetsItem ? [allWidgetsItem, ...alphabeticalSort] : alphabeticalSort
			return sortedBanners
		},
		brandFilterItems () {
			if (brandOptions.value.length >= 2) {
				return [
					{
						value: null,
						text: 'All'
					},
					...brandOptions.value
				]
			} else {
				return brandOptions.value
			}
		},
		showBrandFilter () {
			return brandOptions.value.length >= 2
		},
		dashboardOptions () {
			return this.dashboards.map(({ id, name, isDefault }) => ({
				value: id,
				text: name,
				selected: isDefault
			}))
		},
		showCookieFilter () {
			const COOKIE_DASHBOARD = 0
			if (this.dashboardValue === COOKIE_DASHBOARD && (this.productAreaPermission(CAN_SEE_CONSENT_BANNERS) || this.productAreaPermission(CAN_SEE_COOKIE_BANNERS))) {
				this.getCookieDomains()
				return true
			} else {
				return this.dashboards.find(x => x.id === this.dashboardValue)?.enableCookieFilter ?? false
			}
		},
		periodOptions () {
			return [
				{
					value: Periods.LastWeek,
					text: 'Last week'
				},
				{
					value: Periods.LastMonth,
					text: 'Last month'
				},
				{
					value: Periods.Last3Months,
					text: 'Last 3 months'
				},
				{
					value: Periods.Last6Months,
					text: 'Last 6 months'
				},
				{
					value: Periods.Last12Months,
					text: 'Last 12 months'
				},
				{
					value: Periods.Custom,
					text: 'Custom'
				}
			]
		},
		domainItems () {
			const items = []
			if ((this.domains?.length || 0) > 1) {
				items.push({ value: 'All', text: 'All', selected: true })
			}
			items.push(...this.domains.map(domain => ({
				value: domain,
				text: domain !== null ? domain : 'No Domain Captured'
			})))
			if (items.length === 1 && items[0].value !== 'All') {
				items[0].value = 'All'
			}
			return items
		}
	},
	async created () {
		try {
			await this.getDashboards(this.productAreaPermission(CAN_SEE_CONSENT_BANNERS))
			this.dashboardValue = this.dashboards.find(x => x.id === this.userDefaultDashboardId)?.id
			if (!this.dashboardValue && this.dashboardValue !== 0) {
				this.dashboardValue = this.dashboards.find(x => x.isDefault)?.id
			}
			this.changeDashboardValue(this.dashboardValue)
			await waitForLoad()
			if (this.productAreaPermission(CAN_SEE_CONSENT_BANNERS)) {
				getCookieWidgets().then(data => {
					if (data?.cookieWidgets != null) {
						this.consentBanners.push(...data.cookieWidgets)
					}
				})
			}
		} catch (err) {
			console.error(err)
		}
	},
	methods: {
		...mapActions('dashboards', ['getDashboards']),
		changeDashboardValue (event) {
			this.$emit('dashboard-change', event)
			this.dashboardValue = event
		},
		getCookieDomains () {
			getDomains().then(data => {
				this.domains = data
			})
		}
	}
}
</script>
