<template>
	<SectionCard>
		<template #title>
			{{ data.title }}
		</template>
		<template #body>
			<LineChart
				v-if="chartData.datasets.length > 0"
				:height="200"
				:chart-data="chartData"
				:chart-options="chartOptions"
			/>
			<div v-else>
				No data available for selected filters.
			</div>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import LineChart from '../../../../../../shared/components/chart/line-chart.vue'
import { getRandomColorByString } from '../../../../../../shared/utils/getRandomColorByString.js'

export default {
	components: {
		LineChart,
		SectionCard
	},
	props: {
		items: Array,
		selected: Function,
		data: Object
	},
	data () {
		return {
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	computed: {
		chartData () {
			const { xAxis, series } = this.data

			const labels = xAxis.categories
			const datasets = []

			series.forEach(({ data, name }) => {
				datasets.push({
					label: name,
					data,
					borderColor: getRandomColorByString(name)
				})
			})

			return {
				labels,
				datasets
			}
		}
	}
}
</script>
