<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Dashboard' | useLabels }}
		</template>
		<template #header-caption>
			View and edit {{ 'dashboards' | useLabels }}
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot name="content" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
