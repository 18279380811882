<template>
	<v-card>
		<v-card-title>
			{{ title }}
			<v-spacer />
			<div
				class="health-status-indicator"
				:class="amberStatus ? 'amber darken-3' : 'green accent-4'"
			/>
		</v-card-title>
	</v-card>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		amberStatus: {
			type: Boolean,
			required: false,
			default: null
		}
	}
}
</script>

<style lang="scss">
.health-status-indicator {
	width: 20px;
	height: 20px;
	border-radius: 50px;
}
</style>
